import { render, staticRenderFns } from "./FourTileBanner.vue?vue&type=template&id=2b435b31"
import script from "./FourTileBanner.vue?vue&type=script&lang=ts"
export * from "./FourTileBanner.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/frontend/components/widgets/common/Banner.vue').default,WidgetContainer: require('/frontend/components/widgets/common/WidgetContainer.vue').default})
