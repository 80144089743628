
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import WidgetMixin from '~/mixins/widget.mixin';
import Swiper from 'swiper';

@Component({
  name: 'dn-four-tile-banner',
})
export default class FourTileBanner extends mixins(WidgetMixin) {
  @Prop({ type: Object, required: true }) data!: any;
  @Prop({ type: String, default: '' }) redirectUrl!: string;
  @Prop({ type: String, default: '' }) redirectText!: string;
  @Prop({ type: String, default: '' }) dateFrom!: string;
  @Prop({ type: String, default: '' }) dateTo!: string;

  private swiper: Swiper | null = null;
  private get swiperName(): string {
    return `swiper-fourtilebanner`;
  }

  mounted() {
    this.$nextTick(() => {
      if (this.data.bannerFiles?.length > 1) {
        this.initSwiper();
      }
    });
  }

  beforeDestroy() {
    window.removeEventListener('load', this.initSwiper);
    if (this.swiper) {
      this.swiper.destroy?.();
      this.swiper = null;
    }
  }

  initSwiper() {
    if (document.readyState === 'complete') {
      this.createSwiper();
    } else {
      window.addEventListener('load', this.createSwiper);
    }
  }

  createSwiper() {
    this.swiper = new Swiper(`#${this.swiperName}`, {
      slidesPerView: 'auto',
      spaceBetween: 16,
    });
  }
}
